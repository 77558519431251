import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "if-a-loved-one-contracts-covid-19"
    }}>{`If a loved one contracts COVID-19`}</h2>
    <p>{`None of us have lived through a global pandemic before. Even if your teen or family have dealt with illnesses in the past, COVID-19 brings with it different challenges `}{`—`}{` for example not being able to visit a person when they are sick, uncertainty about the long-term effects of the virus, and constant media coverage.`}</p>
    <p>{`Your teen may respond to the news of a loved one contracting COVID-19 in many different ways. They might be worried, angry, or find it hard to believe. They could be optimistic or pessimistic about the situation. All of these reactions are normal and not necessarily reason for concern. If your teen is struggling to cope with how they’re feeling, think about how you could support them.`}</p>
    <p>{`Consider the situation below. Raghu’s grandmother, who lives overseas, has contracted the virus. She is now staying in a quarantine facility. `}<b>{`Of the responses below, which do you think would be most helpful?`}</b></p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m5/21.svg" alt="Listening to Child" mdxType="SingleImage" />
    <Paper mdxType="Paper">
  <Quiz id="quizLovedOneCovid1" singleAnswer={true} question="“She’s always been healthy. Don’t worry too much, I’m sure she’ll be fine.”" fallbackResponse={<div>
        <b>Not helpful</b>. Although it’s tempting to reassure your teen to ease their anxiety, it’s important to
        provide honest, accurate information about the loved one’s situation. Also avoid dismissing your teen’s anxiety,
        such as telling them not to worry. If your teen is worried, let them know it’s okay to feel that way, and offer
        your support.
      </div>} answers={[{
        name: 'Helpful',
        correct: false
      }, {
        name: 'Not Helpful',
        correct: true
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizLovedOneCovid2" singleAnswer={true} question="“Grandma’s situation is serious, it’s possible she won’t survive.  But the doctors are doing all they can to help her.”" fallbackResponse={<div>
        <b>Helpful</b>. If the loved one is in acute danger of dying, don’t hide this from your teen. It will only make
        it harder for you to break the news to them if the person dies. You can assure your teen that their loved one is
        receiving the care they need (e.g. the doctors/hospital are trying their best, they are in good hands).
      </div>} answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizLovedOneCovid3" singleAnswer={true} question="“Oh no, this is a disaster! She’s 85, there’s no way she can recover!”" fallbackResponse={<div>
        <b>Not helpful</b>. Do your best to remain calm when talking with your teen. Try to avoid catastrophising or
        focusing on the worst case scenario. If the loved one is likely to die, have a calm but matter-of-fact
        conversation with your teen.
      </div>} answers={[{
        name: 'Helpful',
        correct: false
      }, {
        name: 'Not Helpful',
        correct: true
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizLovedOneCovid4" singleAnswer={true} fallbackResponse={<div>
        <b>Helpful</b>. Your teen might feel worried or guilty if they can’t be there with their loved one. Assure your
        teen that this is out of their control, and that it’s not their fault.”
      </div>} question="“Remember that this situation is beyond your control. I know it’s hard not being there. But it’s not your fault, or anyone else’s fault.”" answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizLovedOneCovid5" singleAnswer={true} fallbackResponse={<div>
        <b>Helpful</b>. If your teen has questions that you can’t answer, it’s OK to tell them you don’t know. Try to
        find out the answer if you can, and get back to your teen later.
      </div>} question="“I can see you’re worried and have lots of questions. I don’t have all the answers right now. But as soon as I get an update from the doctors about how she’s going, I’ll let you know.”" answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizLovedOneCovid6" singleAnswer={true} fallbackResponse={<div>
        <b>Helpful</b>. The most important thing you can do is be there for your teen. They may not want to talk with
        you straight away, but let them know you are there for support, whenever they need it.
      </div>} question="“I can see you’re upset and angry. I’m here for you any time if you want to talk about it, or if you just want some company.”" answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/34-keep-in-mind.png" alt="Keep in Mind" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      